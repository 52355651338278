export const environment = {
  production: true,
  api: 'https://devservices.sequation.net',
  enableCtrl: true,
  azureBlobStorage: 'https://sequationdevsav2.blob.core.windows.net',
  azureCDNStorageUrl: 'https://sequationdevsav2.blob.core.windows.net',
  moodleURL: 'https://devservices.sequation.net/ccmoodle/',
  appInsights: {
    instrumentationKey: '451221f0-7c79-4813-9ea3-a8b385b2fd5b'
  },
  organizationId : 415,
  microsoftClarityProjectId : 'fbyp2e2dhw',
  appEnv:'THIS IS A DEV ENVIRONMENT'
};
