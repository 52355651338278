import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundPercent'
})
export class RoundPercentPipe implements PipeTransform {

    transform(value: number): string {
      if (value === Math.floor(value)) {
        return `${value.toFixed(0)} `;
      } else {
        return `${value.toFixed(2)} `;
      }
    }

}
